/* eslint-disable no-const-assign */
import {
  SOCKET_ADDED_NEW_CARD,
  SOCKET_AUTO_RECHARGE_WALLET,
  SUCCESS_ADD_CARD,
  SUCCESS_AUTO_RECHARGE_WALLET,
  SUCCESS_DELETE_CARD,
  SUCCESS_MAKE_WALLET_PRIMARY,
  SUCCESS_SEND_TIP,
  SUCCESS_SET_DEFAULT_CARD,
  SUCCESS_TOPUP_MY_WALLET,
} from "Actions/CardAction";
import {
  SET_MASS_MESSAGE_NOTIFICATIONS,
  SOCKET_ADD_NEW_MESSAGE,
  SOCKET_MASS_MESSAGE_QUEUE_UPDATE,
  SUCCESS_DELETE_CHAT,
  SUCCESS_PURCHASE_MESSAGE,
  SUCCESS_READ_MESSAGE,
} from "Actions/ChatAction";
import {
  SOCKET_ADD_NEW_NOTIFICATION,
  SUCCESS_READ_ALL_NOTIFICATION,
} from "Actions/NotificationAction";
import {
  SOCKET_CREATE_POST,
  SOCKET_DELETE_POST,
  SUCCESS_HIDE_USERS_POSTS_FROM_FEED_ACTION,
  SUCCESS_UNLOCK_POST,
} from "Actions/PostAction";
import {
  SUCCESS_CREATE_CHAT_MSG,
  SUCCESS_UPDATE_CHAT_SETTINGS,
  SUCCESS_DELETE_WELCOME_MSG,
  SUCCESS_DISCONNECT_PHONE_NUMBER,
  SUCCESS_TOGGLE_TWO_STEP_AUTH,
} from "Actions/SettingsAction";
import {
  START_GET_GEO_LOCATION,
  SUCCESS_GET_GEO_LOCATION,
  ERROR_GET_GEO_LOCATION,
  CHANGE_THEME_MODE,
  RESET_USER_STATE,
  START_REGISTER_USER,
  SUCCESS_REGISTER_USER,
  ERROR_REGISTER_USER,
  START_LOGIN_USER,
  SUCCESS_LOGIN_USER,
  ERROR_LOGIN_USER,
  START_GET_USER,
  SUCCESS_GET_USER,
  ERROR_GET_USER,
  START_FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  ERROR_FORGOT_PASSWORD,
  START_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  ERROR_RESET_PASSWORD,
  START_UPDATE_USER_PROFILE_IMAGE,
  SUCCESS_UPDATE_USER_PROFILE_IMAGE,
  ERROR_UPDATE_USER_PROFILE_IMAGE,
  START_UPDATE_COVER_IMAGE,
  SUCCESS_UPDATE_COVER_IMAGE,
  ERROR_UPDATE_COVER_IMAGE,
  START_UPDATE_USER_DETAILS,
  SUCCESS_UPDATE_USER_DETAILS,
  ERROR_UPDATE_USER_DETAILS,
  START_DELETE_PROFILE_IMAGE,
  SUCCESS_DELETE_PROFILE_IMAGE,
  ERROR_DELETE_PROFILE_IMAGE,
  START_DELETE_COVER_IMAGE,
  SUCCESS_DELETE_COVER_IMAGE,
  ERROR_DELETE_COVER_IMAGE,
  START_GET_OTHER_USER_PROFILE,
  SUCCESS_GET_OTHER_USER_PROFILE,
  ERROR_GET_OTHER_USER_PROFILE,
  START_CHECK_USERNAME_EXISTS,
  SUCCESS_CHECK_USERNAME_EXISTS,
  ERROR_CHECK_USERNAME_EXISTS,
  START_UPDATE_USERNAME,
  SUCCESS_UPDATE_USERNAME,
  ERROR_UPDATE_USERNAME,
  START_UPDATE_PHONE_NUMBER,
  SUCCESS_UPDATE_PHONE_NUMBER,
  ERROR_UPDATE_PHONE_NUMBER,
  START_UPDATE_PASSWORD,
  SUCCESS_UPDATE_PASSWORD,
  ERROR_UPDATE_PASSWORD,
  START_FORGOT_PASSWORD_CODE_SEND,
  SUCCESS_FORGOT_PASSWORD_CODE_SEND,
  ERROR_FORGOT_PASSWORD_CODE_SEND,
  START_VERIFY_EMAIL,
  SUCCESS_VERIFY_EMAIL,
  ERROR_VERIFY_EMAIL,
  START_VERIFY_EMAIL_CODE,
  SUCCESS_VERIFY_EMAIL_CODE,
  ERROR_VERIFY_EMAIL_CODE,
  START_UPDATE_EMAIL,
  SUCCESS_UPDATE_EMAIL,
  ERROR_UPDATE_EMAIL,
  START_VERIFY_UPDATE_EMAIL_CODE,
  SUCCESS_VERIFY_UPDATE_EMAIL_CODE,
  ERROR_VERIFY_UPDATE_EMAIL_CODE,
  SOCKET_VERIFY_EMAIL,
  START_DELETE_ACCOUNT,
  SUCCESS_DELETE_ACCOUNT,
  ERROR_DELETE_ACCOUNT,
  START_CHANGE_ACTIVITY_STATUS,
  SUCCESS_CHANGE_ACTIVITY_STATUS,
  ERROR_CHANGE_ACTIVITY_STATUS,
  START_LOG_OUT_USER,
  SUCCESS_LOG_OUT_USER,
  ERROR_LOG_OUT_USER,
  SOCKET_LOGIN_USER,
  SOCKET_LOGOUT_USER,
  START_GET_SUGGESTIONS,
  SUCCESS_GET_SUGGESTIONS,
  ERROR_GET_SUGGESTIONS,
  START_SUBSCRIBE_USER,
  SUCCESS_SUBSCRIBE_USER,
  ERROR_SUBSCRIBE_USER,
  START_GET_FOLLOWINGS,
  SUCCESS_GET_FOLLOWINGS,
  ERROR_GET_FOLLOWINGS,
  START_GET_FOLLOWERS,
  SUCCESS_GET_FOLLOWERS,
  ERROR_GET_FOLLOWERS,
  SOCKET_FOLLOWER_ADDED,
  START_BLOCK_USER,
  SUCCESS_BLOCK_USER,
  ERROR_BLOCK_USER,
  START_UNBLOCK_USER,
  SUCCESS_UNBLOCK_USER,
  ERROR_UNBLOCK_USER,
  START_GET_BLOCKED_USER_LIST,
  SUCCESS_GET_BLOCKED_USER_LIST,
  ERROR_GET_BLOCKED_USER_LIST,
  START_ADD_COUNTRY_LEGAL,
  SUCCESS_ADD_COUNTRY_LEGAL,
  ERROR_ADD_COUNTRY_LEGAL,
  START_IDV_PROCESS,
  SUCCESS_IDV_PROCESS,
  ERROR_IDV_PROCESS,
  START_REPORT,
  SUCCESS_REPORT,
  ERROR_REPORT,
  SOCKET_VERIFY_IDENTITY,
  START_ADD_SOCIAL_MEDIA,
  SUCCESS_ADD_SOCIAL_MEDIA,
  ERROR_ADD_SOCIAL_MEDIA,
  START_UPDATE_SOCIAL_MEDIA,
  SUCCESS_UPDATE_SOCIAL_MEDIA,
  ERROR_UPDATE_SOCIAL_MEDIA,
  START_DELETE_SOCIAL_MEDIA,
  SUCCESS_DELETE_SOCIAL_MEDIA,
  ERROR_DELETE_SOCIAL_MEDIA,
  START_CONNECT_USER,
  SUCCESS_CONNECT_USER,
  ERROR_CONNECT_USER,
  START_DISCONNECT_USER,
  SUCCESS_DISCONNECT_USER,
  ERROR_DISCONNECT_USER,
  SOCKET_CONNECT_USER,
  SOCKET_DISCONNECT_USER,
  START_SWITCH_ACCOUNT,
  SUCCESS_SWITCH_ACCOUNT,
  ERROR_SWITCH_ACCOUNT,
  SET_USER_TYPE,
  START_VERIFY_OTP,
  SUCCESS_VERIFY_OTP,
  ERROR_VERIFY_OTP,
} from "Actions/UserAction";
import { parseAmount } from "utils/generalUtils";
import { SUCCESS_ADD_PAYOUT_METHOD } from "Actions/PayoutAction";
import {
  SUCCESS_SET_SUBSCRIPTION_PRICE,
  SUCCCESS_ADD_SUBSCRIPTION_BUNDLE,
  SUCCESS_DELETE_SUBSCRIPTION_BUNDLE,
  SUCCESS_ADD_PROMOTION_CAMPAIGN,
  SUCCESS_DELETE_PROMOTION_CAMPAIGN,
  SUCCESS_STOP_PROMOTION_CAMPAIGN,
  SUCCESS_SUBSCRIPTION_ACTION,
} from "Actions/SubscriptionAction";
import { SUCCESS_PURCHASE_STREAM } from "Actions/StreamAction";

const initialState = {
  token: "",
  themeMode: {
    mode: "",
  },
  geolocation: {
    isLoading: false,
    isFromRussia : false,
    data: {},
    error: "",
  },
  registerDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  loginDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  otpVerificationDetail: {},
  verifyOtpData: {
    isLoading: false,
    data: {},
    error: "",
  },
  userDetail: {
    isLoading: false,
    data: {},
    error: "",
    isUpdatingDetail: false,
    loading: true,
  },
  forgotPasswordDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  resetPasswordDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateProfileImageDetail: {
    isLoading: false,
    error: "",
    data: {},
  },
  updateCoverImageDetail: {
    isLoading: false,
    error: "",
    data: {},
  },
  deleteProfileImageDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteCoverImageDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  otherUserProfileData: {
    isLoading: false,
    data: {},
    error: "",
  },
  checkUsernameExistsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateUsernameDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  updatePhoneDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  updatedPasswordDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  forgotPasswordCodeDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  verifyEmailData: {
    isLoading: false,
    data: {},
    error: "",
  },
  verifyEmailCodeDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateEmailDetail: {
    isLoading: false,
    data: {},
    error: "",
    codeSent: false,
  },
  verifyUpdateEmailDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteAccountDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  changeActivityStatusDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  logoutUserDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  getSuggestionData: {
    isLoading: false,
    data: {},
    error: "",
    isRefreshing: false,
  },
  subscribeUserData: {
    isLoading: false,
    data: {},
    error: "",
    subscribed: false,
  },
  followingsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  followersData: {
    isLoading: false,
    data: {},
    error: "",
  },
  blockUserData: {
    isLoading: false,
    data: {},
    error: "",
  },
  unblockUserData: {
    isLoading: false,
    data: {},
    error: "",
  },
  blockedUserList: {
    isLoading: false,
    data: {},
    error: "",
  },
  addCountryData: {
    isLoading: false,
    data: {},
    error: "",
  },
  idvProcessData: {
    isLoading: false,
    data: {},
    error: "",
  },
  reportDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  addSocialMediaData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateSocialMediaData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteSocialMediaData: {
    isLoading: false,
    data: {},
    error: "",
  },
  connectUserData: {
    isLoading: false,
    data: {},
    error: "",
  },
  disconenctUserData: {
    isLoading: false,
    data: {},
    error: "",
  },
  switchAccountData: {
    isLoading: false,
    data: {},
    error: "",
  },
  userCounts: {
    post_count: 0,
    media_count: 0,
  },
};

export const userReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_USER_STATE: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case SUCCESS_SUBSCRIPTION_ACTION: {
      let userDetail = { ...state.userDetail?.data };
      userDetail["data"]["subscription_price_per_month"] = action?.price || 0;
      if (action?.data?.card_id == "deduct_from_wallet") {
        userDetail["data"]["wallet"] = {
          ...userDetail?.data?.wallet,
          balance: parseAmount(
            userDetail?.data?.wallet?.balance - action?.amount
          ),
        };
      }

      userDetail["data"]["subscribed_to_count"] = !action?.payload?.user
        ?.subscribed_to
        ? userDetail?.data?.subscribed_to_count - 1
        : action?.payload?.user?.was_subscribed
        ? userDetail?.data?.subscribed_to_count
        : userDetail?.data?.subscribed_to_count + 1;

      let followingUsers = { ...state.followingsData?.data };

      if (
        !action?.payload?.user?.subscribed_to &&
        followingUsers?.data?.following_users?.length
      ) {
        followingUsers["data"]["following_users"] =
          followingUsers?.data?.following_users?.filter(
            (user) => user?.id != action?.payload?.user?.id
          );
        followingUsers["data"]["following_user_count"] =
          followingUsers?.data?.following_user_count - 1;
      } else if (followingUsers?.data?.following_users?.length) {
        followingUsers["data"]["following_users"] =
          followingUsers?.data?.following_users?.map((item) => {
            if (item?.id == action?.payload?.user?.id) {
              return {
                ...item,
                ...action?.payload?.user,
              };
            } else {
              return item;
            }
          });
      }
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: userDetail,
        },
        followingsData: {
          ...state.followingsData,
          data: followingUsers,
        },
      };
    }
    case SUCCESS_STOP_PROMOTION_CAMPAIGN: {
      let userDetail = { ...state.userDetail?.data };
      userDetail["data"]["promotion_campaigns"] =
        userDetail?.data?.promotion_campaigns?.map((item) => {
          if (item?.id == action?.promotion_campaign_id) {
            return {
              ...item,
              is_active: false,
            };
          } else {
            return item;
          }
        });
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: userDetail,
        },
      };
    }
    case SUCCESS_DELETE_PROMOTION_CAMPAIGN: {
      let userDetail = { ...state.userDetail?.data };
      userDetail["data"]["promotion_campaigns"] =
        userDetail?.data?.promotion_campaigns?.filter(
          (item) => item?.id != action?.campaign_id
        );
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: userDetail,
        },
      };
    }
    case SUCCESS_ADD_PROMOTION_CAMPAIGN: {
      let userDetail = { ...state.userDetail?.data };
      if (userDetail?.data?.promotion_campaigns?.length > 0) {
        let index = userDetail?.data?.promotion_campaigns?.findIndex(
          (obj) =>
            obj?.subscriber_type == action?.payload?.bundle?.subscriber_type
        );
        if (
          action?.payload?.bundle?.subscriber_type == "Both new and expired"
        ) {
          userDetail["data"]["promotion_campaigns"] = [action?.payload?.bundle];
        } else if (index != -1) {
          let newarr = userDetail?.data?.promotion_campaigns;
          newarr[index] = action?.payload?.bundle;
        } else {
          userDetail["data"]["promotion_campaigns"] = [
            action?.payload?.bundle,
            ...userDetail?.data?.promotion_campaigns?.filter(
              (item) => item?.subscriber_type != "Both new and expired"
            ),
          ];
        }
      } else {
        userDetail["data"]["promotion_campaigns"] = [action?.payload?.bundle];
      }
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: userDetail,
        },
      };
    }
    case SUCCESS_DELETE_SUBSCRIPTION_BUNDLE: {
      let userDetail = { ...state.userDetail?.data };
      userDetail["data"]["bundles"] = userDetail?.data?.bundles?.filter(
        (item) => item?.id != action?.bundle_id
      );
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: userDetail,
        },
      };
    }
    case SUCCCESS_ADD_SUBSCRIPTION_BUNDLE: {
      let userDetail = { ...state.userDetail?.data };
      if (userDetail?.data?.bundles?.length > 0) {
        userDetail["data"]["bundles"] = [
          {
            ...action?.payload?.bundle,
            duration: action?.payload?.bundle?.duration?.toString(),
          },
          ...userDetail?.data?.bundles,
        ];
      } else {
        userDetail["data"]["bundles"] = [
          {
            ...action?.payload?.bundle,
            duration: action?.payload?.bundle?.duration?.toString(),
          },
        ];
      }
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: userDetail,
        },
      };
    }
    case SUCCESS_SET_SUBSCRIPTION_PRICE: {
      let userDetail = { ...state.userDetail };
      userDetail["data"]["data"] = {
        ...userDetail?.data?.data,
        subscription_price_per_month: action?.price || 0,
      };
      return {
        ...state,
        userDetail,
      };
    }
    case SUCCESS_DELETE_WELCOME_MSG: {
      let userDetail = { ...state.userDetail };
      userDetail["data"]["data"] = {
        ...userDetail?.data?.data,
        chat_welcome_msg_new_fan: false,
      };
      return {
        ...state,
        userDetail,
      };
    }
    case SUCCESS_UPDATE_CHAT_SETTINGS: {
      let userDetail = { ...state.userDetail };
      userDetail["data"]["data"] = {
        ...userDetail?.data?.data,
        ...action?.data,
      };
      return {
        ...state,
        userDetail,
      };
    }
    case SUCCESS_ADD_PAYOUT_METHOD: {
      let userDetail = { ...state.userDetail };
      userDetail["data"]["data"]["idv_verification_status"] = true;
      userDetail["data"]["data"]["is_payout_selected"] = 1;
      return {
        ...state,
        userDetail,
      };
    }
    case CHANGE_THEME_MODE: {
      return {
        ...state,
        themeMode: {
          mode: action.mode,
        },
      };
    }
    case START_GET_GEO_LOCATION: {
      return {
        ...state,
        geolocation: {
          isLoading: true,
          isFromRussia : false,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_GEO_LOCATION: {
      return {
        ...state,
        geolocation: {
          isLoading: false,
          data: action.payload,
          isFromRussia : action.payload.country === "RU",
          error: "",
        },
      };
    }
    case ERROR_GET_GEO_LOCATION: {
      return {
        ...state,
        geolocation: {
          isLoading: false,
          isFromRussia : false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_REGISTER_USER: {
      return {
        ...state,
        registerDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_REGISTER_USER: {
      return {
        ...state,
        registerDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        token: action.token,
      };
    }
    case ERROR_REGISTER_USER: {
      return {
        ...state,
        registerDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_LOGIN_USER: {
      return {
        ...state,
        loginDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_LOGIN_USER: {
      return {
        ...state,
        loginDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        token: action.token,
      };
    }
    case ERROR_LOGIN_USER: {
      return {
        ...state,
        loginDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
        otpVerificationDetail: action.payload,
      };
    }

    case START_VERIFY_OTP: {
      return {
        ...state,
        verifyOtpData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_VERIFY_OTP: {
      return {
        ...state,
        verifyOtpData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        token: action.token,
      };
    }
    case ERROR_VERIFY_OTP: {
      return {
        ...state,
        verifyOtpData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_USER: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail?.data,
          isLoading: true,
          error: "",
          loading: true,
        },
      };
    }
    case SUCCESS_GET_USER: {
      let userData = action.payload;
      userData.data.is_active = 1;
      let userCounts = { ...state?.userCounts };
      userCounts.post_count = userData?.data?.post_count;
      userCounts.media_count = userData?.data?.media_count;
      return {
        ...state,
        userDetail: {
          isLoading: false,
          data: userData,
          loading: false,
          error: "",
        },
        userCounts: userCounts,
      };
    }
    case ERROR_GET_USER: {
      return {
        ...state,
        userDetail: {
          isLoading: false,
          data: {},
          error: "",
          loading: false,
        },
      };
    }
    case START_FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPasswordDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPasswordDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPasswordDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        token: action.token,
      };
    }
    case ERROR_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
        otpVerificationDetail: action.payload,
      };
    }
    case START_UPDATE_USER_PROFILE_IMAGE: {
      return {
        ...state,
        updateProfileImageDetail: {
          isLoading: true,
        },
      };
    }
    case SUCCESS_UPDATE_USER_PROFILE_IMAGE: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              profile_picture_url: action?.payload,
            },
          },
        },
        updateProfileImageDetail: {
          isLoading: false,
        },
      };
    }
    case ERROR_UPDATE_USER_PROFILE_IMAGE: {
      return {
        ...state,
        updateProfileImageDetail: {
          isLoading: false,
          error: action?.payload,
        },
      };
    }
    case START_UPDATE_COVER_IMAGE: {
      return {
        ...state,
        updateCoverImageDetail: {
          isLoading: true,
        },
      };
    }
    case SUCCESS_UPDATE_COVER_IMAGE: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              cover_picture_url: action?.payload,
            },
          },
        },
        updateCoverImageDetail: {
          isLoading: false,
        },
      };
    }
    case ERROR_UPDATE_COVER_IMAGE: {
      return {
        ...state,
        updateCoverImageDetail: {
          isLoading: false,
          error: action?.payload,
        },
      };
    }

    case START_UPDATE_USER_DETAILS: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          isUpdatingDetail: true,
          error: {},
        },
      };
    }

    case SUCCESS_UPDATE_USER_DETAILS: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          isUpdatingDetail: false,
          error: {},
        },
      };
    }
    case ERROR_UPDATE_USER_DETAILS: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          isUpdatingDetail: false,
          error: action.payload,
        },
      };
    }

    case START_DELETE_PROFILE_IMAGE: {
      return {
        ...state,
        deleteProfileImageDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_PROFILE_IMAGE: {
      return {
        ...state,
        deleteProfileImageDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              profile_picture_url: null,
            },
          },
        },
      };
    }
    case ERROR_DELETE_PROFILE_IMAGE: {
      return {
        ...state,
        deleteProfileImageDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_DELETE_COVER_IMAGE: {
      return {
        ...state,
        deleteCoverImageDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_COVER_IMAGE: {
      return {
        ...state,
        deleteCoverImageDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              cover_picture_url: null,
            },
          },
        },
      };
    }
    case ERROR_DELETE_COVER_IMAGE: {
      return {
        ...state,
        deleteCoverImageDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_OTHER_USER_PROFILE: {
      return {
        ...state,
        otherUserProfileData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_OTHER_USER_PROFILE: {
      return {
        ...state,
        otherUserProfileData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_OTHER_USER_PROFILE: {
      return {
        ...state,
        otherUserProfileData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_CHECK_USERNAME_EXISTS: {
      return {
        ...state,
        checkUsernameExistsData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CHECK_USERNAME_EXISTS: {
      return {
        ...state,
        checkUsernameExistsData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_CHECK_USERNAME_EXISTS: {
      return {
        ...state,
        checkUsernameExistsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_USERNAME: {
      return {
        ...state,
        updateUsernameDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_USERNAME: {
      return {
        ...state,
        updateUsernameDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              username: action?.username,
            },
          },
        },
      };
    }
    case ERROR_UPDATE_USERNAME: {
      return {
        ...state,
        updateUsernameDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_PHONE_NUMBER: {
      return {
        ...state,
        updatePhoneDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_PHONE_NUMBER: {
      return {
        ...state,
        updatePhoneDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              mobile_number: action?.phone,
              two_step_auth_enabled: true,
            },
          },
        },
      };
    }
    case SUCCESS_DISCONNECT_PHONE_NUMBER: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              mobile_number: "",
              two_step_auth_enabled: false,
            },
          },
        },
      };
    }
    case SUCCESS_TOGGLE_TWO_STEP_AUTH: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              two_step_auth_enabled: action.payload?.two_step_auth_enabled,
              two_step_auth_enabled_email: action.payload?.two_step_auth_enabled_email,
            },
          },
        },
      };
    }
    case ERROR_UPDATE_PHONE_NUMBER: {
      return {
        ...state,
        updatePhoneDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_PASSWORD: {
      return {
        ...state,
        updatedPasswordDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_PASSWORD: {
      return {
        ...state,
        updatedPasswordDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              is_password_empty: 0,
            },
          },
        },
      };
    }
    case ERROR_UPDATE_PASSWORD: {
      return {
        ...state,
        updatedPasswordDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_FORGOT_PASSWORD_CODE_SEND: {
      return {
        ...state,
        forgotPasswordCodeDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_FORGOT_PASSWORD_CODE_SEND: {
      return {
        ...state,
        forgotPasswordCodeDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_FORGOT_PASSWORD_CODE_SEND: {
      return {
        ...state,
        forgotPasswordCodeDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_VERIFY_EMAIL: {
      return {
        ...state,
        verifyEmailData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_VERIFY_EMAIL: {
      return {
        ...state,
        verifyEmailData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_VERIFY_EMAIL: {
      return {
        ...state,
        verifyEmailData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_VERIFY_EMAIL_CODE: {
      return {
        ...state,
        verifyEmailCodeDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_VERIFY_EMAIL_CODE: {
      return {
        ...state,
        verifyEmailCodeDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_VERIFY_EMAIL_CODE: {
      return {
        ...state,
        verifyEmailCodeDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_EMAIL: {
      return {
        ...state,
        updateEmailDetail: {
          isLoading: true,
          data: {},
          error: "",
          codeSent: false,
        },
      };
    }
    case SUCCESS_UPDATE_EMAIL: {
      return {
        ...state,
        updateEmailDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
          codeSent: true,
        },
      };
    }
    case ERROR_UPDATE_EMAIL: {
      return {
        ...state,
        updateEmailDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
          codeSent: false,
        },
      };
    }
    case START_VERIFY_UPDATE_EMAIL_CODE: {
      return {
        ...state,
        verifyUpdateEmailDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_VERIFY_UPDATE_EMAIL_CODE: {
      return {
        ...state,
        verifyUpdateEmailDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              email: action?.email,
            },
          },
        },
      };
    }
    case ERROR_VERIFY_UPDATE_EMAIL_CODE: {
      return {
        ...state,
        verifyUpdateEmailDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case SET_USER_TYPE: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              user_type: action?.payload?.type,
            },
          },
        },
      };
    }

    case SOCKET_VERIFY_EMAIL: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              email_verified_at: action.payload.email_verified_at,
            },
          },
        },
      };
    }

    case START_DELETE_ACCOUNT: {
      return {
        ...state,
        deleteAccountDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_ACCOUNT: {
      return {
        ...state,
        deleteAccountDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_DELETE_ACCOUNT: {
      return {
        ...state,
        deleteAccountDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CHANGE_ACTIVITY_STATUS: {
      return {
        ...state,
        changeActivityStatusDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CHANGE_ACTIVITY_STATUS: {
      return {
        ...state,
        changeActivityStatusDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              show_activity_status: action.payload.is_active,
            },
          },
        },
      };
    }
    case ERROR_CHANGE_ACTIVITY_STATUS: {
      return {
        ...state,
        changeActivityStatusDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_LOG_OUT_USER: {
      return {
        ...state,
        logoutUserDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_LOG_OUT_USER: {
      return {
        ...state,
        logoutUserDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_LOG_OUT_USER: {
      return {
        ...state,
        logoutUserDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_SUGGESTIONS: {
      let oldData = { ...state?.getSuggestionData?.data };
      return {
        ...state,
        getSuggestionData: {
          isLoading: action.refresh
            ? false
            : oldData?.suggested_users?.length > 0
            ? false
            : true,
          data: action.refresh
            ? oldData
            : oldData?.suggested_users?.length > 0
            ? oldData
            : {},
          error: "",
          isRefreshing: action.refresh ? true : false,
        },
      };
    }
    case SUCCESS_GET_SUGGESTIONS: {
      return {
        ...state,
        getSuggestionData: {
          isLoading: false,
          data: action.payload,
          error: "",
          isRefreshing: false,
        },
      };
    }
    case ERROR_GET_SUGGESTIONS: {
      return {
        ...state,
        getSuggestionData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_SUBSCRIBE_USER: {
      return {
        ...state,
        subscribeUserData: {
          isLoading: true,
          data: {},
          error: "",
          subscribed: false,
        },
      };
    }
    case SUCCESS_SUBSCRIBE_USER: {
      let otherUserProfileData = { ...state?.otherUserProfileData?.data?.user };
      if (
        action?.followStatus &&
        Object.keys(state?.otherUserProfileData?.data)?.length > 0
      ) {
        otherUserProfileData.subscribed_to = 1;
      } else if (Object.keys(state?.otherUserProfileData?.data)?.length > 0) {
        otherUserProfileData = state?.otherUserProfileData?.data?.user;
        otherUserProfileData.subscribed_to = 0;
      }
      let userDetail = { ...state?.userDetail?.data?.data };
      userDetail.subscribed_to_count = action?.followStatus
        ? userDetail?.subscribed_to_count + 1
        : userDetail?.subscribed_to_count - 1;
      if (!action?.followStatus) {
        let followingUsers = state?.followingsData?.data;
        followingUsers.following_users =
          followingUsers?.following_users?.filter(
            (user) => user?.id != action?.user_id
          );
      }
      return {
        ...state,
        subscribeUserData: {
          isLoading: false,
          data: action.payload,
          error: "",
          subscribed: true,
        },
        otherUserProfileData: {
          ...state?.otherUserProfileData,
          data: {
            ...state?.otherUserProfileData?.data,
            user: otherUserProfileData,
          },
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }
    case ERROR_SUBSCRIBE_USER: {
      return {
        ...state,
        subscribeUserData: {
          isLoading: false,
          data: {},
          error: action.payload,
          subscribed: false,
        },
      };
    }

    case START_GET_FOLLOWINGS: {
      let oldList = { ...state?.followingsData?.data };
      return {
        ...state,
        followingsData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_FOLLOWINGS: {
      let oldList = { ...state?.followingsData?.data };
      if (oldList?.following_users?.length > 0) {
        oldList.following_users = [
          ...oldList?.following_users,
          ...action?.payload?.following_users,
        ];
      } else {
        oldList = action?.payload;
      }
      return {
        ...state,
        followingsData: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_FOLLOWINGS: {
      return {
        ...state,
        followingsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_FOLLOWERS: {
      let oldList = { ...state?.followersData?.data };
      return {
        ...state,
        followersData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_FOLLOWERS: {
      let oldList = { ...state?.followersData?.data };
      if (oldList?.followers?.length > 0) {
        oldList.followers = [
          ...oldList?.followers,
          ...action?.payload?.followers,
        ];
      } else {
        oldList = action?.payload;
      }
      return {
        ...state,
        followersData: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_FOLLOWERS: {
      return {
        ...state,
        followersData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_BLOCK_USER: {
      return {
        ...state,
        blockUserData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_BLOCK_USER: {
      let otherUserProfileData = { ...state?.otherUserProfileData?.data?.user };
      let followingData = { ...state?.followingsData?.data };
      let followerData = { ...state?.followersData?.data };
      if (Object.keys(state?.otherUserProfileData?.data)?.length > 0) {
        otherUserProfileData.is_blocked = 1;
      }
      if (Object.keys(state?.followingsData?.data)?.length > 0) {
        followingData.following_users = followingData?.following_users?.filter(
          (item) => item?.id != action?.user_id
        );
      }
      if (Object.keys(state?.followersData?.data)?.length > 0) {
        followerData.followers = followerData?.followers?.filter(
          (item) => item?.id != action?.user_id
        );
      }
      return {
        ...state,
        blockUserData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              blocked_count:
                (state?.userDetail?.data?.data?.blocked_count || 0) + 1,
            },
          },
        },
        otherUserProfileData: {
          ...state?.otherUserProfileData,
          data: {
            ...state?.otherUserProfileData?.data,
            user: otherUserProfileData,
          },
        },
        followingsData: {
          ...state?.followingsData,
          data: followingData,
        },
        followersData: {
          ...state?.followersData,
          data: followingData,
        },
      };
    }
    case ERROR_BLOCK_USER: {
      return {
        ...state,
        blockUserData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UNBLOCK_USER: {
      return {
        ...state,
        unblockUserData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UNBLOCK_USER: {
      let otherUserProfileData = { ...state?.otherUserProfileData?.data?.user };
      let blockedUSerList = { ...state?.blockedUserList?.data };
      if (Object.keys(state?.otherUserProfileData?.data)?.length > 0) {
        otherUserProfileData.is_blocked = 0;
      }
      if (Object.keys(state?.blockedUserList?.data)?.length > 0) {
        blockedUSerList.blocked_users = blockedUSerList?.blocked_users?.filter(
          (user) => user?.id != action?.user_id
        );
      }
      return {
        ...state,
        unblockUserData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              blocked_count:
                (state?.userDetail?.data?.data?.blocked_count || 0) - 1,
            },
          },
        },
        otherUserProfileData: {
          ...state?.otherUserProfileData,
          data: {
            ...state?.otherUserProfileData?.data,
            user: otherUserProfileData,
          },
        },
        blockedUSerList: {
          ...state?.blockedUSerList,
          data: blockedUSerList,
        },
      };
    }
    case ERROR_UNBLOCK_USER: {
      return {
        ...state,
        unblockUserData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_BLOCKED_USER_LIST: {
      return {
        ...state,
        blockedUserList: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_BLOCKED_USER_LIST: {
      return {
        ...state,
        blockedUserList: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_BLOCKED_USER_LIST: {
      return {
        ...state,
        blockedUserList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_ADD_COUNTRY_LEGAL: {
      return {
        ...state,
        addCountryData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_ADD_COUNTRY_LEGAL: {
      return {
        ...state,
        addCountryData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              country: action?.country,
              is_adult: true,
            },
          },
        },
      };
    }
    case ERROR_ADD_COUNTRY_LEGAL: {
      return {
        ...state,
        addCountryData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_IDV_PROCESS: {
      return {
        ...state,
        idvProcessData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_IDV_PROCESS: {
      let newUrl = window.open(action?.payload?.ondato_url);
      if (!newUrl || newUrl?.closed || typeof newUrl?.closed == "undefined") {
        window.open(action?.payload?.ondato_url, "_self");
      }
      return {
        ...state,
        idvProcessData: {
          isLoading: false,
          data: action?.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              idv_precess_started: true,
            },
          },
        },
      };
    }
    case ERROR_IDV_PROCESS: {
      return {
        ...state,
        idvProcessData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_REPORT: {
      return {
        ...state,
        reportDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_REPORT: {
      return {
        ...state,
        reportDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_REPORT: {
      return {
        ...state,
        reportDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_ADD_SOCIAL_MEDIA: {
      return {
        ...state,
        addSocialMediaData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_ADD_SOCIAL_MEDIA: {
      return {
        ...state,
        addSocialMediaData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              social_media_buttons: [
                action.payload?.social_media_button,
                ...state?.userDetail?.data?.data?.social_media_buttons,
              ],
            },
          },
        },
      };
    }
    case ERROR_ADD_SOCIAL_MEDIA: {
      return {
        ...state,
        addSocialMediaData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_UPDATE_SOCIAL_MEDIA: {
      return {
        ...state,
        updateSocialMediaData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_SOCIAL_MEDIA: {
      let userDetail = { ...state?.userDetail?.data?.data };
      userDetail.social_media_buttons = userDetail?.social_media_buttons?.map(
        (item) => {
          if (item?.id == action?.payload?.social_media_button?.id) {
            return {
              ...item,
              ...action?.payload?.social_media_button,
            };
          } else {
            return item;
          }
        }
      );
      return {
        ...state,
        updateSocialMediaData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }
    case ERROR_UPDATE_SOCIAL_MEDIA: {
      return {
        ...state,
        updateSocialMediaData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_DELETE_SOCIAL_MEDIA: {
      return {
        ...state,
        deleteSocialMediaData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_SOCIAL_MEDIA: {
      let userDetail = { ...state?.userDetail?.data?.data };
      userDetail.social_media_buttons =
        userDetail?.social_media_buttons?.filter(
          (item) => item?.id != action?.button_id
        );
      return {
        ...state,
        deleteSocialMediaData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }
    case ERROR_DELETE_SOCIAL_MEDIA: {
      return {
        ...state,
        deleteSocialMediaData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case SUCCESS_HIDE_USERS_POSTS_FROM_FEED_ACTION: {
      let otherUserProfileData = { ...state?.otherUserProfileData?.data?.user };
      if (action.hide_user_id) {
        otherUserProfileData.isPostHide = !otherUserProfileData.isPostHide;
      }

      return {
        ...state,
        otherUserProfileData: {
          ...state?.otherUserProfileData,
          data: {
            ...state?.otherUserProfileData?.data,
            user: otherUserProfileData,
          },
        },
      };
    }

    case SOCKET_LOGIN_USER: {
      const { user_id, show_activity_status = true } = action.payload;
      let otherUserProfileData = { ...state?.otherUserProfileData?.data?.user };
      if (otherUserProfileData?.id == user_id) {
        otherUserProfileData.is_active = 1;
        otherUserProfileData.show_activity_status = show_activity_status;
      }
      let suggestions = { ...state?.getSuggestionData?.data };
      suggestions.suggested_users = suggestions?.suggested_users?.map(
        (user) => {
          if (user?.id == user_id) {
            return {
              ...user,
              is_active: 1,
              show_activity_status: show_activity_status,
            };
          } else {
            return user;
          }
        }
      );
      let followingUsers = { ...state?.followingsData?.data };
      followingUsers.following_users = followingUsers?.following_users?.map(
        (user) => {
          if (user?.id == user_id) {
            return {
              ...user,
              is_active: 1,
              show_activity_status: show_activity_status,
            };
          } else {
            return user;
          }
        }
      );
      let followers = { ...state?.followersData?.data };
      followers.followers = followers?.followers?.map((user) => {
        if (user?.id == user_id) {
          return {
            ...user,
            is_active: 1,
            show_activity_status: show_activity_status,
          };
        } else {
          return user;
        }
      });
      return {
        ...state,
        otherUserProfileData: {
          ...state?.otherUserProfileData,
          data: {
            ...state?.otherUserProfileData?.data,
            user: otherUserProfileData,
          },
        },
        getSuggestionData: {
          ...state?.getSuggestionData,
          data: suggestions,
        },
        followingsData: {
          ...state?.followingsData,
          data: followingUsers,
        },
        followersData: {
          ...state?.followersData,
          data: followers,
        },
      };
    }

    case SOCKET_LOGOUT_USER: {
      const { user_id } = action.payload;
      let otherUserProfileData = { ...state?.otherUserProfileData?.data?.user };
      if (otherUserProfileData?.id == user_id) {
        otherUserProfileData.is_active = 0;
      }
      let suggestions = { ...state?.getSuggestionData?.data };
      suggestions.suggested_users = suggestions?.suggested_users?.map(
        (user) => {
          if (user?.id == user_id) {
            return {
              ...user,
              is_active: 0,
            };
          } else {
            return user;
          }
        }
      );
      let followingUsers = { ...state?.followingsData?.data };
      followingUsers.followers = followingUsers?.following_users?.map(
        (user) => {
          if (user?.id == user_id) {
            return {
              ...user,
              is_active: 0,
            };
          } else {
            return user;
          }
        }
      );
      let followers = { ...state?.followersData?.data };
      followers.following_users = followers?.followers?.map((user) => {
        if (user?.id == user_id) {
          return {
            ...user,
            is_active: 0,
          };
        } else {
          return user;
        }
      });
      return {
        ...state,
        otherUserProfileData: {
          ...state?.otherUserProfileData,
          data: {
            ...state?.otherUserProfileData?.data,
            user: otherUserProfileData,
          },
        },
        getSuggestionData: {
          ...state?.getSuggestionData,
          data: suggestions,
        },
        followingsData: {
          ...state?.followingsData,
          data: followingUsers,
        },
        followersData: {
          ...state?.followersData,
          data: followers,
        },
      };
    }

    case SOCKET_FOLLOWER_ADDED: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (
        action?.payload?.followed_user_id ==
        root?.user?.userDetail?.data?.data?.id
      ) {
        userDetail.subscriber_count = action.payload?.follow
          ? userDetail?.subscriber_count + 1
          : userDetail?.subscriber_count - 1;
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SOCKET_ADD_NEW_MESSAGE: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              unread_count:
                action?.payload?.message?.from_user_id ==
                root?.user?.userDetail?.data?.data?.id
                  ? state?.userDetail?.data?.data?.unread_count
                  : state?.userDetail?.data?.data?.unread_count + 1,
            },
          },
        },
      };
    }

    case SOCKET_ADD_NEW_NOTIFICATION: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              notification_count:
                state?.userDetail?.data?.data?.notification_count + 1,
            },
          },
        },
      };
    }

    case SUCCESS_READ_ALL_NOTIFICATION: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              notification_count: 0,
            },
          },
        },
      };
    }

    case SOCKET_VERIFY_IDENTITY: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (action?.payload?.status == "Approved") {
        userDetail.ondato_verification_status = 1;
        userDetail.idv_precess_started = 0;
        userDetail.user_type = "fan";
        userDetail.idv_verification_status = true;
      } else if (action?.payload?.status == "Rejected") {
        userDetail.idv_precess_started = 0;
        userDetail.ondato_verification_status = 0;
      } else {
        userDetail.ondato_verification_status = 0;
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SUCCESS_MAKE_WALLET_PRIMARY: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              is_wallet_primary_rebill_method:
                !state?.userDetail?.data?.data?.is_wallet_primary_rebill_method,
            },
          },
        },
      };
    }

    case SUCCESS_AUTO_RECHARGE_WALLET: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              auto_recharge_wallet: action?.data?.auto_recharge_wallet,
              auto_recharge_min_amount: action?.data?.auto_recharge_min_amount,
              auto_recharge_amount: action?.data?.auto_recharge_amount,
            },
          },
        },
      };
    }

    case SUCCESS_ADD_CARD: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (userDetail?.cards?.length > 0) {
        userDetail.cards = [action?.payload?.card, ...userDetail?.cards];
      } else {
        userDetail.cards = [action?.payload?.card];
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SUCCESS_DELETE_CARD: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (userDetail?.cards?.length > 0) {
        userDetail.cards = userDetail?.cards?.filter(
          (item) => item?.id != action?.card_id
        );
      }
      if (!userDetail?.cards?.length) {
        userDetail.auto_recharge_wallet = false;
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SUCCESS_SET_DEFAULT_CARD: {
      let userDetail = { ...state?.userDetail?.data?.data };
      userDetail.cards = userDetail?.cards?.map((item) => {
        if (item?.id == action?.id) {
          return {
            ...item,
            is_default: true,
          };
        } else {
          return {
            ...item,
            is_default: false,
          };
        }
      });
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SUCCESS_TOPUP_MY_WALLET: {
      let userDetail = { ...state?.userDetail?.data?.data };
      userDetail.wallet = {
        ...userDetail?.wallet,
        balance:
          Number(parseAmount(userDetail?.wallet?.balance)) + action?.amount,
      };
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SUCCESS_SEND_TIP: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (action?.data?.card_id == "deduct_from_wallet") {
        userDetail.wallet = {
          ...userDetail?.wallet,
          balance: parseAmount(
            userDetail?.wallet?.balance - action?.data?.amount
          ),
        };
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SUCCESS_UNLOCK_POST: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (action?.data?.card_id == "deduct_from_wallet") {
        userDetail.wallet = {
          ...userDetail?.wallet,
          balance: parseAmount(
            userDetail?.wallet?.balance - action?.data?.amount
          ),
        };
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SUCCESS_PURCHASE_MESSAGE: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (action?.data?.card_id == "deduct_from_wallet") {
        userDetail.wallet = {
          ...userDetail?.wallet,
          balance: parseAmount(
            userDetail?.wallet?.balance - action?.data?.amount
          ),
        };
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }
    case SUCCESS_PURCHASE_STREAM: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (action?.data?.card_id == "deduct_from_wallet") {
        userDetail.wallet = {
          ...userDetail?.wallet,
          balance: parseAmount(
            userDetail?.wallet?.balance - action?.data?.amount
          ),
        };
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }
    case SOCKET_AUTO_RECHARGE_WALLET: {
      let userDetail = { ...state?.userDetail?.data?.data };
      userDetail.wallet = {
        ...userDetail?.wallet,
        balance: parseAmount(
          parseAmount(userDetail?.wallet?.balance) + action?.payload?.amount
        ),
      };
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SUCCESS_CREATE_CHAT_MSG: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (action?.paymentDetail?.card_id == "deduct_from_wallet") {
        userDetail.wallet = {
          ...userDetail?.wallet,
          balance: parseAmount(
            userDetail?.wallet?.balance - action?.paymentDetail?.amount
          ),
        };
      }
      userDetail.chat_welcome_msg_new_fan = true;
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SOCKET_CREATE_POST: {
      const { post } = action.payload;
      let userDetail = { ...state?.userDetail?.data?.data };
      let userCounts = { ...state?.userCounts };
      if (userDetail?.id == post?.user?.id) {
        userDetail.post_count = userDetail?.post_count + 1;
        userDetail.media_count =
          userDetail?.media_count +
          post?.post_videos?.length +
          (post?.post_images?.length || 0);
      }
      userCounts.post_count = userCounts?.post_count + 1;
      userCounts.media_count =
        userCounts?.media_count +
        post?.post_videos?.length +
        (post?.post_images?.length || 0);
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
        userCounts: userCounts,
      };
    }

    case SOCKET_DELETE_POST: {
      const { post_id } = action.payload;
      let newPosts = { ...root?.post?.getAllPostData?.data };
      let myNewPosts = { ...root?.post?.myPostsData?.data };
      let filteredPost = myNewPosts?.posts?.filter(
        (post) => post?.id == post_id
      )[0];
      let userDetail = { ...state?.userDetail?.data?.data };
      let userCounts = { ...state?.userCounts };

      if (userDetail?.id == filteredPost?.user?.id) {
        userDetail.post_count =
          userDetail?.post_count - 1 > 0 ? userDetail?.post_count - 1 : 0;
        userDetail.media_count =
          userDetail?.media_count -
          (filteredPost?.post_videos?.length || 0) -
          (filteredPost?.post_images?.length || 0);
      }
      userCounts.post_count = userCounts?.post_count
        ? userCounts?.post_count - 1
        : userDetail.post_count;
      userCounts.media_count = userCounts?.media_count
        ? userCounts?.media_count -
          (filteredPost?.post_videos?.length || 0) -
          (filteredPost?.post_images?.length || 0)
        : userDetail.media_count;

      return {
        ...state,
        userCounts: userCounts,
      };
    }

    case SUCCESS_READ_MESSAGE: {
      let chatList = { ...root?.chat?.chatListData };
      let userDetail = { ...state?.userDetail?.data?.data };
      if (action?.read_type == "fresh") {
        chatList?.list?.map((chat) => {
          if (chat?.chat_id == action?.chat_id) {
            userDetail.unread_count =
              userDetail?.unread_count - chat?.unread_count;
          }
        });
      } else if (action?.read_type == "open") {
        chatList?.list?.map((chat) => {
          if (chat?.chat_id == action?.chat_id) {
            userDetail.unread_count =
              userDetail?.unread_count - chat?.unread_msg_count;
          }
        });
      } else if (action?.read_type == "all") {
        userDetail.unread_count = 0;
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SUCCESS_DELETE_CHAT: {
      let userDetail = { ...state?.userDetail?.data?.data };
      let chatList = { ...root?.chat?.chatListData };
      let deletedChatUnreadCount = 0;
      chatList?.list?.map((item) => {
        if (action?.chat_ids?.includes(item?.chat_id)) {
          deletedChatUnreadCount =
            deletedChatUnreadCount + (item?.unread_count || 0);
        }
      });
      userDetail.unread_count =
        userDetail?.unread_count - deletedChatUnreadCount;
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case START_CONNECT_USER: {
      return {
        ...state,
        connectUserData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CONNECT_USER: {
      let linkedAccounts = [];
      if (Object.keys(action.payload?.data || {})?.length) {
        linkedAccounts = [action.payload.data];
      }
      return {
        ...state,
        connectUserData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              linked_accounts: linkedAccounts,
            },
          },
        },
      };
    }
    case ERROR_CONNECT_USER: {
      return {
        ...state,
        connectUserData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_DISCONNECT_USER: {
      return {
        ...state,
        disconenctUserData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DISCONNECT_USER: {
      return {
        ...state,
        disconenctUserData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              linked_accounts: [],
            },
          },
        },
      };
    }
    case ERROR_DISCONNECT_USER: {
      return {
        ...state,
        disconenctUserData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case SOCKET_CONNECT_USER: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              linked_accounts: [action?.payload],
            },
          },
        },
      };
    }

    case SOCKET_DISCONNECT_USER: {
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: {
              ...state?.userDetail?.data?.data,
              linked_accounts: [],
            },
          },
        },
      };
    }

    case START_SWITCH_ACCOUNT: {
      return {
        ...state,
        switchAccountData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SWITCH_ACCOUNT: {
      return {
        ...state,
        switchAccountData: {
          isLoading: false,
          data: {},
          error: "",
        },
      };
    }
    case ERROR_SWITCH_ACCOUNT: {
      return {
        ...state,
        switchAccountData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case SOCKET_ADDED_NEW_CARD: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (userDetail?.cards?.length > 0) {
        userDetail.cards = [action?.payload?.card, ...userDetail?.cards];
      } else {
        userDetail.cards = [action?.payload?.card];
      }
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SOCKET_MASS_MESSAGE_QUEUE_UPDATE: {
      let userDetail = { ...state?.userDetail?.data?.data };
      if (userDetail?.mass_messages?.length > 0) {
        let existingMassMessages = userDetail?.mass_messages?.filter(
          (item) => item?.id == action?.payload?.id
        );
        if (existingMassMessages?.length) {
          userDetail.mass_messages = userDetail?.mass_messages?.map((item) =>
            item?.id == action?.payload?.id
              ? { ...item, ...action?.payload }
              : item
          );
        } else {
          userDetail.mass_messages = [
            ...userDetail?.mass_messages,
            action.payload,
          ];
        }
      } else {
        userDetail.mass_messages = [action.payload];
      }

      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    case SET_MASS_MESSAGE_NOTIFICATIONS: {
      let userDetail = { ...state?.userDetail?.data?.data };
      userDetail.mass_messages = userDetail?.mass_messages?.filter(
        (item) => item?.id != action.payload?.id
      );
      return {
        ...state,
        userDetail: {
          ...state?.userDetail,
          data: {
            ...state?.userDetail?.data,
            data: userDetail,
          },
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
