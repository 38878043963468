// Auth
export const GEO_LOCATION_ENDPOINT = "/geolocation";
export const REGISTER_USER_ENDPOINT = "/auth/register";
export const LOGIN_USER_ENDPOINT = "/auth/login";
export const FORGOT_PASSWORD_ENDPOINT = "/auth/forgot-password";
export const RESET_PASSWORD_ENDPOINT = "/auth/reset-password";
export const LOG_OUT_USER_ENDPOINT = "/auth/logout";
export const SEND_OTP_CODE_ENDPOINT = "/auth/otp";
export const VERIFY_OTP_CODE_ENDPOINT = "/auth/otp-verify";

// User
export const GET_USER_ENDPOINT = "/user/my-profile";
export const UPDATE_USER_PROFILE_IMAGE_ENDPOINT =
  "/user/my-profile/profile-picture";
export const UPDATE_COVER_IMAGE_ENDPOINT = "/user/my-profile/cover-picture";
export const UPDATE_USER_DETAIL_ENDPOINT = "/user/my-profile";
export const UPDATE_COVER_IMAGE_SERVER_ENDPOINT =
  "/user/my-profile/update-cover-picture";
export const UPDATE_PROFILE_IMAGE_SERVER_ENDPOINT =
  "/user/my-profile/update-profile-picture";
export const DELETE_PROFILE_IMAGE_ENDPOINT = "/user/my-profile/profile-picture";
export const DELETE_COVER_IMAGE_ENDPOINT = "/user/my-profile/cover-picture";
export const GET_OTHER_USER_PROFILE_ENDPOINT = "/user/{user_id}";
export const START_IDV_PROCESS_ENDPOINT = "/user/kyc";

// Blocking
export const BLOCK_USER_ENDPOINT = "/user/block/{user_id}";
export const UNBLOCK_USER_ENDPOINT = "/user/block/{user_id}";
export const GET_BLOCKED_USER_ENDPOINT = "/user/block/list";

// Report
export const REPORT_ENDPOINT = "/reporting";

// Fan Followings
export const GET_SUGGESTIONS_ENDPOINT = "/suggestions/users";
export const SUBSCRIBE_USER_ENDPOINT = "/user/following/{user_id}";
export const GET_FOLLOWINGS_ENDPOINT = "/user/following";
export const GET_FOLLOWERS_ENDPOINT = "/user/followers";

// Settings
export const SEND_SECURITY_CODE_ENDPOINT =
  "/settings/account/send-security-code";
export const VERIFY_SECURITY_CODE_ENDPOINT =
  "/settings/account/verify-security-code";
export const CHECK_USERNAME_EXISTS_ENDPOINT =
  "/settings/account/check-username";
export const UPDATE_USERNAME_ENDPOINT = "/settings/account/update-username";
export const UPDATE_PHONE_ENDPOINT = "/settings/account/change-phone";
export const UPDATE_PASSWORD_ENDPOINT = "/settings/account/change-password";
export const SEND_FORGOT_PASSOWRD_CODE_EXNPOINT =
  "/settings/account/forgot-password";
export const VERIFY_EMAIL_ENDPOINT = "/settings/account/verify-email";
export const VERIFY_EMAIL_CODE_ENDPOINT = "/settings/account/verify-email";
export const UPDATE_EMAIL_ENDPOiNT = "/settings/account/change-email";
export const VERIFY_UPDATE_EMAIL_CODE_ENDPOINT =
  "/settings/account/change-email-successful";
export const DELETE_ACCOUNT_ENDPOINT = "/settings/account/delete-account";
export const SHOW_ACTIVITY_STATUS_ENDPOINT =
  "/settings/account/show-activity-status";
export const ADD_COUNTRY_LEGAL_ENDPOINT = "/user/my-profile/country";
export const ADD_SOCIAL_MEDIA_ENDPOINT = "/social-media-buttons";
export const UPDATE_SOCIAL_MEDIA_ENDPOINT = "/social-media-buttons/{button_id}";
export const DELETE_SOCIAL_MEDIA_ENDPOINT = "/social-media-buttons/{button_id}";
export const UPDATE_CHAT_SETTING_ENDPOINT = "/chats/setting";
export const DELETE_WELCOME_MSG_ENDPOINT = "/welcome-message/{id}";
export const GET_WELCOME_MSG_ENDPOINT = "/welcome-message";
export const CREATE_WELCOME_MSG_ENDPOINT = "/welcome-message/create";
export const UPDATE_WELCOME_MSG_ENDPOINT = "/welcome-message";
export const CONNECT_USER_ENDPOINT = "/user/connect";
export const DISCONNECT_USER_ENDPOINT = "/user/disconnect";
export const SWITCH_ACCOUNT_ENDPOINT = "/user/switch-account";
export const DISCONNECT_PHONE_ENDPOINT = "/settings/account/disconnect-phone";
export const TOGGLE_TWO_STEP_AUTH_ENDPOINT =
  "/settings/account/change-2fa-status";
export const GET_AUTH_CODES_ENDPOINT = "/settings/account/show-codes";
export const LOGIN_SESSION_ENDPOINT = "/user/login-sessions";
export const USER_METADATA_ENDPOINT = "/settings/account/user-meta";

// Tracking Links
export const CREATE_TRACKING_LINK_ENDPOINT = "/tracking-links";
export const DELETE_TRACKING_LINK_ENDPOINT = "/tracking-links/{id}";
export const GET_TRACKING_LINK_ENDPOINT = "/tracking-links";
export const CLICK_TRACK_LINK_ENDPOINT = "/tracking-links/click";
export const GET_TRACKING_LINK_SUBSCRIBERS_ENDPOINT =
  "/tracking-links/subscribers/{id}";

// Subscription Bundle
export const SET_SUBSCRIPTION_PRICE_ENDPOINT =
  "/settings/account/set-subscription-price";
export const GET_FREE_TRIAL_LINKS_ENDPOINT = "/subscription/free-trial-links";
export const CREATE_FREE_TRIAL_LINKS_ENDPOINT =
  "/subscription/free-trial-links";
export const DELETE_FREE_TRIAL_LINKS_ENDPOINT =
  "/subscription/free-trial-links/{link_id}";
export const CHECK_FREE_TRIAL_AVAILABILITY_ENDPOINT =
  "/subscription/free-trial-links/check";
export const JOIN_FREE_TRIAL_ENDPOINT =
  "/subscription/free-trial-links/subscribe";
export const ADD_SUBSCRIPTION_BUNDLE_ENDPOINT = "/subscription/bundles";
export const DELETE_SUBSCIPTION_BUNDLE_ENDPOINT =
  "/subscription/bundles/{bundle_id}";
export const ADD_PROMOTION_CAMPAIGN_ENDPOINT =
  "/subscription/promotion-campaigns";
export const DELETE_PROMOTION_CAMPAIGN_ENDPOINT =
  "/subscription/promotion-campaigns/{campaign_id}";
export const STOP_PROMOTION_CAMPAIGN_ENDPOINT =
  "/subscription/promotion-campaigns/stop";
export const SUBSCRIPTION_ACTION_ENDPOINT = "/user/following/{user_id}";

// Post
export const CREATE_POST_ENDPOINT = "/user/posts";
export const CREATE_NEW_POST_ENDPOINT = "/user/posts/create";
export const CREATE_POST_SERVER_ENDPOINT =
  "/user/posts/successful-uploads/{post_id}";
export const GET_ALL_POST_ENDPOINT = "/user/posts";
export const DELETE_POST_ENDPOINT = "/user/posts/{post_id}";
export const GET_POSTS_BY_ID = "/user/posts/find";
export const UPDATE_POST_ENDPOINT = "/user/posts/{post_id}";
export const GET_OTHER_USER_POSTS_ENDPOINT = "/user/{user_id}/posts";
export const LIKE_POST_ENDPOINT = "user/posts/{post_id}/likes";
export const GET_POST_COMMENTS_ENDPOINT = "/user/posts/{post_id}/comments";
export const CREATE_POST_COMMENT_ENDPOINT =
  "/user/posts/{post_id}/comments/create";
export const UPDATE_COMMENT_ENDPOINT =
  "/user/posts/{post_id}/comments/{comment_id}/update";
export const DELETE_COMMENT_ENDPOINT =
  "/user/posts/{post_id}/comments/{comment_id}/delete";
export const GET_COMMENT_REPLIES_ENDPOINT =
  "/user/posts/{post_id}/comments/{comment_id}/replies";
export const ADD_BOOKMARK_ENDPOINT = "/bookmarks/create";
export const REMOVE_BOOKMARK_ENDPOINT = "/bookmarks/delete";
export const GET_BOOKMARK_ENDPOINT = "/bookmarks";
export const GET_BOOKMARK_MEDIA_ENDPOINT = "/bookmarks/media";
export const LINK_PRIVIEW_ENDPOINT = "/common/get-link-preview";
export const GET_PROFILE_MEDIA_ENDPOINT = "/user/media";
export const UNLOCK_POST_ENDPOINT = "/user/posts/buy";
export const GET_MENTION_USERS_ENDPOINT = "/user/mention";
// Post Creators
export const UPDATE_POST_CREATORS_ENDPOINT = "/post-creators";

// Chat
export const GET_CHAT_LIST_ENDPOINT = "/chats/messages";
export const GET_NEW_MESSAGE_LIST_ENDPOINT = "/chats/recents";
export const CREATE_NEW_CHAT_ENDPOINT = "/chats/new";
export const GET_CHAT_CONVERSATION_ENDPOINT = "/chats/messages/{chat_id}";
export const SEND_MESSAGE_ENDPOINT = "/chats/send-message";
export const READ_MESSAGE_ENDPOINT = "/chats/messages/mark-as-read";
export const DELETE_CHAT_ENDPOINT = "/chats/messages/clear";
export const GET_CHAT_MEDIA_URL_ENDPOINT = "/chats/send-message/upload-media";
export const DELETE_CHAT_MESSAGES_ENDPOINT =
  "/chats/messages/{chat_id}/delete-message";
export const PURCHASE_MESSAGE_ENDPOINT = "/chats/message/buy";
export const INITIALIZE_CHAT_MULTIPART_UPLOAD_ENDPOINT =
  "/chats/initialize-multipart-upload";
export const FINALIZE_CHAT_MULTIPART_UPLOAD_ENDPOINT =
  "/chats/finalize-multipart-upload";
export const CREATE_CHAT_MESSAGE_ENDPOINT = "/chats/create";
export const GET_SINGLE_MASS_MESSAGE_ENDPOINT = "/mass-messages/{message_id}";
export const UPDATE_MASS_MESSAGE_ENDPOINT = "/mass-messages/{message_id}";

// Notification
export const GET_NOTIFICATION_ENDPOINT = "/notifications";
export const READ_SINGLE_NOTIFICATION_ENDPOINT = "/notifications/mark-as-read";
export const READ_ALL_NOTIFICATION_ENDPOINT = "/notifications/mark-all-as-read";

// Streaming
export const CREATE_NEW_STREAM_ENDPOINT = "/user/create-streaming-channel";
export const STOP_STREAM_ENDPOINT = "/user/stop-streaming";
export const GET_STREAM_BY_ID_ENDPOINT = "/user/streaming/{stream_id}";
export const JOIN_LIVE_STREAM_ENDPOINT =
  "/user/join-live-streaming/{stream_id}";
export const LEAVE_LIVE_STREAM_ENDPOINT =
  "/user/leave-live-streaming/{stream_id}";
export const SHARE_STREAM_AS_POST_ENDPOINT =
  "/user/streaming-as-post/{stream_id}";
export const PLAY_STREAM_VIDEO_ENDPOINT = "common/play-video";
export const PURCHASE_STREAM_ENDPOINT = "/user/streaming-buy";
export const UPDATE_TIP_GOAL_ENDPOINT = "/user/streaming-goal/{stream_id}";

// Statements
export const GET_MY_REFERRALS_ENDPOINT = "/referrals/my-referrals";

// Search
export const SEARCH_USER_AND_POST_ENDPOINT = "/search";
export const SEARCH_USER_ENDPOINT = "/search/users";

// Twitch SSO
export const GET_TWITCH_USER_DATA = "https://api.twitch.tv/helix/users";

// Payment Card
export const ADD_CARD_ENDPOINT = "/cards";
export const GET_CARD_LIST_ENDPOINT = "/cards/list";
export const DELETE_CARD_ENDPOINT = "/cards/{card_id}";
export const SET_DEFAULT_CARD_ENDPOINT = "/cards/{card_id}/set-as-default";
export const UPDATE_CARD_ADDRESS_ENDPOINT = "/cards/{card_id}";
export const SEND_TIP_ENDPOINT = "/tips/{user_id}";
export const GET_TRANSACTIONS_ENDPOINT = "/wallet/transactions/list";
export const GET_LATEST_TRANSACTION_ENDPOINT = "/wallet/transactions/latest";

// Wallet
export const MAKE_WALLET_PRIMARY_ENDPOINT = "/wallet/use-wallet-for-rebills";
export const AUTO_RECHARGE_WALLET_ENDPOINT = "/wallet/auto-recharge-wallet";
export const GET_MY_WALLET_ENDPOINT = "/wallet/";
export const TOPUP_MY_WALLET_ENDPOINT = "/wallet/add";

// Payout
export const GET_BANK_DETAIL_ENDPOINT = "/user/bank-details";
export const ADD_BANKING_DETAILS_ENDPOINT = "/user/bank-details";
export const ADD_BANK_DOCUMENT_SERVER_CALL_ENDPOINT =
  "/user/bank-details/success";
export const SEND_PAYOUT_SECURITY_CODE_ENDPOINT =
  "/user/bank-details/send-bank-otp";
export const ADD_PAYOUT_METHOD_ENDPOINT = "/user/bank-detail/verify-bank-otp";
export const SUBMIT_W9_FORM_ENDPOINT = "/w9-forms/submit";
export const DOWNLOAD_W9_FORM_ENDPOINT = "/w9-forms/download";
export const SUBMIT_W8_FORM_ENDPOINT = "/w8-forms/submit";

// Statements
export const GET_STATEMENT_DETAIL_ENDPOINT = "/payout/balance";
export const GET_EARNINGS_LIST_ENDPOINT = "/payout/list";
export const CHANGE_PAYOUT_TYPE_ENDPOINT = "/payout/auto-payout-type";
export const GET_PAYOUT_REQUESTS_ENDPOINT = "/payout/payout-requests";
export const GET_REFERRAL_EARNINGS_ENDPOINT = "/payout/referrals";
export const MANUAL_PAYOUT_ENDPOINT = "/payout/payout-manually";
export const GET_EARNING_STATISTICS_ENDPOINT = "/payout/earning-statistics";

// List
export const CREATE_LIST_ENDPOINT = "/list";
export const GET_LIST_ENDPOINT = "/list";
export const UPDATE_LIST_ENDPOINT = "/list/{id}";
export const CLEAR_LIST_ENDPOINT = "/list/{id}";
export const DELETE_LIST_ENDPOINT = "/list/{id}";
export const GET_SINGLE_LIST_ENDPOINT = "/list/{id}";
export const GET_LIST_USERS_ENDPOINT = "/list/users/{id}";
export const GET_ADD_USER_ENDPOINT = "/list/new-users";
export const ADD_USERS_TO_LIST_ENDPOINT = "/list/list-wise-users";

// Gloabl APIS
export const GET_PUBLIC_PROFILE_POSTS_ENDPOINT = "/user/{user_id}/posts/public";
export const GET_PUBLIC_PROFILE_MEDIA_ENDPOINT = "/user/media/public";
export const GET_PUBLIC_POSTS_BY_ID_ENDPOINT = "/user/posts/find/public";

// Release Forms
export const POST_SIGNED_RELEASE_FORM_ENDPOINT =
  "/release-forms/release-form-documents";
export const GET_SIGNED_RELEASE_FORM_ENDPOINT = "/release-forms";
export const UPDATE_SIGNED_RELEASE_FORM_ENDPOINT = "/release-forms/{id}";
export const UPDATE_RF_DEFAULT_VISIBILITY_ENDPOINT =
  "/release-forms/toggle-default-visibility";
export const VERIFY_GUEST_TOKEN_ENDPOINT = "/user/verify-guest";

// Tag User
export const CREATE_INVITATION_LINK_ENDPOINT = "/tagged-users";
export const UPDATE_INVITATION_LINK_ENDPOINT = "/tagged-users/{id}";
export const UPDATE_LINK_VISIBILITY_ENDPOINT =
  "/tagged-users/toggle-default-visibility";
export const GET_TAGGED_USERS_ENDPOINT = "/tagged-users";

// Common
export const POST_GENERATE_AWS_PUT_OBJECT_SIGNED_URL =
  "/common/generate-signed-url";

// Hints
export const GET_USER_HINTS_ENDPOINT = "/users/hints";
export const DISMISS_USER_HINTS_ENDPOINT = "/users/hints/{id}";

// Statistics
// Earnings
export const GET_STATEMENT_EARNING_STATISTICS_ENDPOINT =
  "/statistics/statements/earnings/chart";
export const GET_STATEMENT_EARNING_LIST_ENDPOINT =
  "/statistics/statements/earnings/list";
export const GET_STATISTICS_CONFIG_ENDPOINT = "/statistics/configs";

// Payouts
export const GET_STATEMENT_PAYOUT_STATISTICS_ENDPOINT =
  "/statistics/statements/payout-requests/chart";
export const GET_STATEMENT_PAYOUT_LIST_ENDPOINT =
  "/statistics/statements/payout-requests/list";

// Chargebacks
export const GET_STATEMENT_CHARGEBACK_STATISTICS_ENDPOINT =
  "/statistics/statements/chargebacks/chart";
export const GET_STATEMENT_CHARGEBACK_LIST_ENDPOINT =
  "/statistics/statements/chargebacks/list";

// Referrals
export const GET_STATEMENT_REFERRALS_STATISTICS_ENDPOINT =
  "/statistics/statements/referrals/chart";
export const GET_STATEMENT_REFERRALS_LIST_ENDPOINT =
  "/statistics/statements/referrals/list";

// Schedule
export const GET_QUEUE_COUNTS_ENDPOINT = "/schedule/counters";
export const GET_QUEUE_LIST_ENDPOINT = "/schedule";
export const TAKE_SCHEDULE_ACTION_ENDPOINT =
  "/schedule/take-action/{action_id}";
export const POST_SAVED_FOR_LATER_ENDPOINT = "/schedule/post";
export const GET_SAVED_FOR_LATER_POSTS_ENDPOINT = "/schedule/later";

// Vault
export const GET_VAULT_LIST_ENDPOINT = "/vault";
export const CREATE_VAULT_LIST_ENDPOINT = "/vault";
export const UPDATE_VAULT_LIST_ENDPOINT = "/vault/{id}";
export const DELETE_VAULT_LIST_ENDPOINT = "/vault/{id}";
export const GET_SINGLE_VAULT_LIST_ENDPOINT = "/vault/{id}";
export const CHANGE_VAULT_LIST_ORDER_ENDPOINT = "/vault/sort";

// Vault Media
export const GET_VAULT_MEDIA_ENDPOINT = "/vault-media";
export const DELETE_VAULT_MEDIA_ENDPOINT = "/vault-media";
export const CHANGE_MEDIA_CATEGORY_ENDPOINT = "/vault-media/{id}";
export const GET_VAULT_MEDIA_BY_ID_ENDPOINT = "/vault-media/media";

// Collab Posts
export const GET_COLLAB_POSTS_ENDPOINT = "/post-collab";
export const TAKE_ACTION_COLLAB_POSTS_ENDPOINT = "/post-collab/take-action";

// Hide User's Posts From Feed
export const HIDE_USERS_POSTS_FROM_FEED_ACTION =
  "/user/posts/hide-user-post/{hide_user_id}";

// I Don't Like Post
export const I_DONT_LIKE_POST_ACTION = "/user/posts/not-like-post/{id}";


// Agency Members
export const GET_AGENCY_MEMBERS = "/agency-members";
export const CREATE_AGENCY_MEMBERS_INVITE = "/agency-members/invite";
// Get Hashtags
export const GET_HASHTAGS_ENDPOINT = "/user/posts/hashtags";
